import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type AppThunk, type RootState, type TypedDispatch } from 'src/app/store/index';
import { Accommodation, AccommodationEntity } from 'src/data/models/Accommodation';
import { EventDetail, EventDetailEntity } from 'src/data/models/EventDetail';
import { fetchEventAccomodations, fetchEventDetail } from 'src/data/services/events';
import { setIsTravelwareAvailable } from './appSlice';

export enum EventStateStatus {
    FETCHING = 'FETCHING',
    OK = 'OK',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    ERROR = 'ERROR',
}

export enum AccommodationStateStatus {
    EMPTY = 'EMPTY',
    FETCHING = 'FETCHING',
    OK = 'OK',
    ERROR = 'ERROR',
}

export enum AccommodationError {
    NOT_FOUND = 'NOT_FOUND',
    UNKNOWN = 'UNKNOWN',
    NONE = 'NONE',
}

export enum EventError {
    NOT_FOUND = 'NOT_FOUND',
    UNKNOWN = 'UNKNOWN',
}

export type EventState = {
    id: string;
    detailEntity: EventDetailEntity | null;
    detailCategoryId: string | null;
    selectedTicketCategoryId: string | null;
    status: EventStateStatus;
    error: EventError | null;
    accommodations: {
        eventId: string;
        status: AccommodationStateStatus;
        data: AccommodationEntity[];
        error: AccommodationError;
    };
};

const initialState: EventState = {
    id: '',
    detailEntity: null,
    detailCategoryId: null,
    selectedTicketCategoryId: null,
    status: EventStateStatus.FETCHING,
    error: null,
    accommodations: {
        eventId: '',
        status: AccommodationStateStatus.EMPTY,
        data: [],
        error: AccommodationError.NONE,
    },
};

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        startFetching: (state: EventState) => {
            state.detailEntity = null;
            state.detailCategoryId = null;
            state.id = '';
            state.status = EventStateStatus.FETCHING;
            state.error = null;
        },
        setDetail: (
            state: EventState,
            action: PayloadAction<{ detail: EventDetailEntity; categoryId: string | null }>
        ) => {
            state.detailEntity = action.payload.detail;
            state.detailCategoryId = action.payload.categoryId;
            state.id = action.payload.detail.id;
            state.status = action.payload.detail.base_package
                ? EventStateStatus.OK
                : EventStateStatus.NOT_AVAILABLE;
        },
        setFetchFailed: (state: EventState, action: PayloadAction<EventError>) => {
            state.id = '';
            state.detailEntity = null;
            state.detailCategoryId = null;
            state.status = EventStateStatus.ERROR;
            state.error = action.payload;
        },
        resetEvent: (state) => {
            Object.assign(state, initialState);
        },
        setSelectedTicket: (state: EventState, action: PayloadAction<string | null>) => {
            state.selectedTicketCategoryId = action.payload;
        },
        startFetchAccommodations: (state: EventState) => {
            state.accommodations.status = AccommodationStateStatus.FETCHING;
            state.accommodations.data = [];
        },
        receiveFetchAccommodations: (
            state: EventState,
            action: PayloadAction<AccommodationEntity[]>
        ) => {
            state.accommodations.status = AccommodationStateStatus.OK;
            state.accommodations.data = action.payload;
        },
        receiveFetchAccommodationsFailed: (
            state: EventState,
            action: PayloadAction<AccommodationError>
        ) => {
            state.accommodations.status = AccommodationStateStatus.ERROR;
            state.accommodations.data = [];
            state.accommodations.error = action.payload;
        },
    },
});

export const { actions } = eventSlice;

export const fetchAccommodations =
    (eventId: string): AppThunk =>
    (dispatch: TypedDispatch<RootState>): Promise<void> => {
        dispatch(actions.startFetchAccommodations());

        return fetchEventAccomodations(eventId)
            .then((accommodations: AccommodationEntity[]) => {
                dispatch(actions.receiveFetchAccommodations(accommodations));
            })
            .catch((err) => {
                if (!err.response) {
                    actions.receiveFetchAccommodationsFailed(AccommodationError.UNKNOWN);
                    throw err;
                }

                actions.receiveFetchAccommodationsFailed(AccommodationError.UNKNOWN);
            });
    };

export const fetchEvent =
    (eventId: string, categoryId: string | null) =>
    (dispatch: TypedDispatch<RootState>): Promise<void> => {
        dispatch(actions.startFetching());
        dispatch(fetchAccommodations(eventId));

        return fetchEventDetail(eventId, categoryId)
            .then((eventDetail) => {
                dispatch(actions.setDetail({ detail: eventDetail, categoryId }));
            })
            .catch((err) => {
                console.log(err);
                if (!err.response) {
                    actions.setFetchFailed(EventError.UNKNOWN);
                    throw err;
                }

                if (err.response.status >= 500) {
                    dispatch(setIsTravelwareAvailable(false));
                    actions.setFetchFailed(EventError.UNKNOWN);
                    return;
                }

                switch (err.response.status) {
                    case 404:
                        dispatch(actions.setFetchFailed(EventError.NOT_FOUND));
                        break;
                    default:
                        dispatch(actions.setFetchFailed(EventError.UNKNOWN));
                        break;
                }
            });
    };

export const selectEventDetail = createSelector(
    (state: RootState) => state.event.detailEntity,
    (detailEntity) => (detailEntity === null ? null : new EventDetail(detailEntity))
);

export const selectEventAccommodations = createSelector(
    (state: RootState) => state.event.accommodations.data,
    (accommodations) => accommodations.map((a) => new Accommodation(a))
);

export const selectEventStatus = (state: RootState): EventStateStatus => state.event.status;

export const selectSelectedTicketCategoryId = (state: RootState): string | null =>
    state.event.selectedTicketCategoryId;

export const { resetEvent, setSelectedTicket } = actions;
export default eventSlice.reducer;
